import * as React from "react"
const Toggle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#000"
        d="M9.386 14.583H2.084v2.084h7.302v3.125l4.156-4.167-4.156-4.167v3.125Zm6.229-1.041v-3.125h7.302V8.333h-7.302V5.208l-4.156 4.167 4.156 4.167Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h25v25H0z" />
      </clipPath>
    </defs>
  </svg>
)
export {Toggle}
