import * as React from "react"
const Close = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="currentColor"
            d="M25 2.518 22.482 0 12.5 9.982 2.518 0 0 2.518 9.982 12.5 0 22.482 2.518 25l9.982-9.982L22.482 25 25 22.482 15.018 12.5 25 2.518Z"
        />
    </svg>
)
export { Close }