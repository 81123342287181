const formattedDate = (date) => {
   return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}
export const getMaxDate = (maxYears) => {
   const currentDate = new Date();
   const maxDate = new Date(currentDate.getFullYear() - maxYears, currentDate.getMonth(), currentDate.getDate());
   return formattedDate(maxDate);
}

export const getMinDate = (minYears) => {
   const currentDate = new Date();
   const minDate = new Date(currentDate.getFullYear() - minYears, currentDate.getMonth(), currentDate.getDate());
   return formattedDate(minDate);
};

export const getToday = () => {
   const currentDate = new Date();
   return formattedDate(currentDate);
}