export const partialCar = {
   returnInOther: false,
   place: "",
   pickDate: "",
   returnDate: "",
   pickHour: "",
   returnHour: "",
   returnPlace: "",
   comments: "",
   datesCar: undefined,
};

export const partialHotel = {
   place: "",
   checkIn: "",
   checkOut: "",
   ocupation: "Sencilla",
   breakfast: false,
   comments: "",
   datesHotel: undefined,
};
export const partialFlight = {
   airportsDeparture: "",
   airportsArrive: "",
   dateReturn: "",
   dateDeparture: "",
   scheduleDeparture: "Mañana",
   scheduleReturn: "Mañana",
   class: "Economy",
   comments: "",
   datesFlights: undefined,
   typeFlight: 'Redondo'
};

export const partialRequest = {
   clientInsurance: false,
   wantFlight: true,
   wantHotel: false,
   wantCar: false,
   typeFlight: "Redondo",
   flight: [{ ...partialFlight }],
   hotel: [{ ...partialHotel }],
   car: [{ ...partialCar }],
};

export const initialValues = {
   clientName: "",
   clientLastname: "",
   clientEmail: "",
   clientPhone: "",
   clientBirthday: "",
   clientBussiness: "",
   clientEvent: "",
   clientDocument: "",
   docImage: "",
   imageName: "",
   request: [
      {
         ...partialRequest,
      },
   ],
};
