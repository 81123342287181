import React from "react";

const ActionButton = ({ children, variant = 'primary', className, disabled, onClick, type }) => {

  const variants = {
    primary: 'bg-primary text-white hover:bg-accent disabled:bg-gray-300',
    secondary: 'bg-transparent border border-solid border-2 border-primary text-primary hover:border-accent hover:text-accent disabled:border-gray-300 disabled:text-gray-300'
  }

  return (
    <button
      className={`${variant === 'primary' ? variants.primary : variants.secondary} py-3 px-4 rounded-[5px] text-md ${className}`}
      disabled={disabled}
      type={type}
      onClick={(e) => {
        if (type !== 'submit') {
          e.preventDefault()
          if (onClick()) {
            onClick()
          }
        }
      }}
    >
      {children}
    </button>
  );
}

export default ActionButton;