import * as React from "react"
const Pointer = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...props}
  >
    <g fill="#000" clipPath="url(#a)">
      <path d="M12.5 2.083a7.286 7.286 0 0 0-7.292 7.292c0 5.469 7.292 13.542 7.292 13.542s7.292-8.073 7.292-13.542A7.286 7.286 0 0 0 12.5 2.083ZM7.292 9.375A5.21 5.21 0 0 1 12.5 4.167a5.21 5.21 0 0 1 5.209 5.208c0 3-3 7.49-5.209 10.292-2.166-2.782-5.208-7.323-5.208-10.292Z" />
      <path d="M12.5 11.98a2.604 2.604 0 1 0 0-5.21 2.604 2.604 0 0 0 0 5.21Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h25v25H0z" />
      </clipPath>
    </defs>
  </svg>
)
export {Pointer}