import * as React from "react"
const Plus = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21px"
    height="21px"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.5.083C4.747.083.083 4.747.083 10.5S4.747 20.917 10.5 20.917 20.917 16.253 20.917 10.5 16.253.083 10.5.083Zm5.208 11.459h-4.166v4.166H9.458v-4.166H5.292V9.458h4.166V5.292h2.084v4.166h4.166v2.084Z"
    />
  </svg>
)
export {Plus}