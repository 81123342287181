import React from 'react';
import DateInput from '../components/DateInput.jsx';
import ActionButton from '../components/ActionButton.jsx';
import { Plus } from '../icons/Plus.jsx';
import { FieldArray } from 'formik';
import { Close } from '../icons/Close.jsx';
import { partialHotel } from "../helpers/initalValues.js";
import Input from '../components/Input.jsx';
import Checkbox from '../components/Checkbox.jsx';
import Select from '../components/Select.jsx';
import TextArea from '../components/TextArea.jsx';
import DateRange from '../components/DateRange.jsx';

const Hotel = ({ indexRequest }) => {

   const ocupation = [{
      value: "Sencilla",
      name: "Sencilla"
   }, {
      value: "Doble",
      name: "Doble",
   }]

   return (
      <FieldArray name={`request[${indexRequest}].hotel`}>
         {({ push, remove, form: { values } }) => {

            return values.request[indexRequest]?.hotel?.map((itm, indexHotel) => (
               <div key={indexHotel}>
                  {values.request[indexRequest]?.hotel?.length > 1 && <>
                     <hr className="border-top border-gray-300 border-dashed h-1 w-full mb-4" />
                     <div className="flex justify-end">
                        <button type="button" onClick={() => remove(indexHotel)}><Close className="scale-[0.7]" /></button>
                     </div>
                  </>}

                  <div className="flex mb-4">
                     <p className="text-md font-semibold me-6">Hotel:</p>
                     <Checkbox label="Con desayuno incluido" name={`request[${indexRequest}].hotel[${indexHotel}].breakfast`} />
                  </div>
                  <div className="mb-1 w-full">
                     <Input placeholder="Destino como una ciudad o punto de interés" name={`request[${indexRequest}].hotel[${indexHotel}].place`} type="text" icon="pointer" />
                  </div>
                  <div className="grid gap-4 mb-1 grid-cols-1">
                     <DateRange name={`request[${indexRequest}].hotel[${indexHotel}].datesHotel`} nameDeparture={`request[${indexRequest}].hotel[${indexHotel}].checkIn`} nameReturn={`request[${indexRequest}].hotel[${indexHotel}].checkOut`} label="Fechas de reservación" />
                  </div>
                  <div className="grid grid-cols-1 gap-4 mb-4 w-full">
                     <Select label="Ocupación" name={`request[${indexRequest}].hotel[${indexHotel}].ocupation`} options={ocupation} />
                  </div>
                  <TextArea label="Comentarios" name={`request[${indexRequest}].hotel[${indexHotel}].comments`} />
                  {(indexHotel === values.request[indexRequest]?.hotel?.length - 1) &&
                     <ActionButton className="flex w-fit gap-4 items-center mb-10" onClick={() => push({ ...partialHotel })}>Agregar hotel <Plus className="text-lg" /></ActionButton>
                  }
               </div>
            ))
         }}
      </FieldArray>
   );
}

export default Hotel;