import React, { useEffect } from "react";
import { useField } from "formik";

const HiddenInput = (props) => {
   const { name, value } = props
   const [field, meta, helpers] = useField(name);

   useEffect(() => {
      helpers.setValue(value)
   }, [value])

   return (
      <input
         {...field}
         {...props}
         hidden
      />
   );
}

export default HiddenInput;