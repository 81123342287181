import React from "react";
import { useField } from "formik";
import Icon from "./Icon";

const Time = (props) => {
  const { label, name, icon } = props
  const [field, meta] = useField(name);

  return (
    <div className="relative">
      {label &&
        <label htmlFor={name} className={`mb-1 text-base font-semibold leading-5 ${meta?.touched && meta?.error ? 'text-red-500' : 'text-[#0A0A0A]'}`}>{label}</label>
      }
      <div className="left-[16px] top-[38px] absolute">
        <Icon type={icon}/>
      </div>
      <input
        {...field}
        {...props}
        id={name}
        type="time"
        className={`border border-solid ${meta?.touched && meta?.error ? 'border-red-500' : 'border-black'} h-[52px] rounded-[5px] w-full placeholder:text-[#ACACAC] py-2 ${icon ? 'pl-12': 'pl-4'} pe-4`}
      />
      
      {meta?.touched && meta?.error &&
        <span className="text-red-500">{meta?.error}</span>
      }
    </div>
  );
}

export default Time;