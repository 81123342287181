import * as React from "react"
const Arrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="17px"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="m15.5.833-1.645 1.645 5.343 5.356H.333v2.333h18.865l-5.355 5.355 1.657 1.645L23.667 9 15.5.833Z"
    />
  </svg>
)
export { Arrow }
