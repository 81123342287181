import * as React from "react"
const Check = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={98}
    height={98}
    fill="none"
    {...props}
  >
    <path
      fill="#00B2B7"
      d="M49 .667C22.32.667.667 22.32.667 49S22.32 97.333 49 97.333 97.333 75.68 97.333 49 75.68.667 49 .667Zm-9.667 72.5L15.167 49l6.815-6.815 17.351 17.303 36.685-36.685 6.815 6.864-43.5 43.5Z"
    />
  </svg>
)
export {Check}