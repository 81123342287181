import React, { useEffect } from 'react'
import { partialFlight } from '../helpers/initalValues'
import HiddenInput from './HiddenInput'
import DateInput from '../components/DateInput';
import ActionButton from './ActionButton'
import TextArea from './TextArea'
import Select from './Select'
import DateRange from './DateRange'
import Input from './Input'
import { Plus } from '../icons/Plus';
import { Close } from '../icons';

const schedules = [{
   value: "Mañana",
   name: "Mañana",
}, {
   value: "Tarde",
   name: "Tarde",
},
{
   value: "Noche",
   name: "Noche",
}]

const classes = [{
   value: "Economy",
   name: "Economy",
}, {
   value: "Premium",
   name: "Premium economy"
}, {
   value: "Businness",
   name: "Ejecutiva / Business"
}
]

function FlightsFields({ push, remove, request, indexRequest, form: { values } }) {

   useEffect(() => {
      if (values?.request[indexRequest].typeFlight === 'Multidestino') {
         push({ ...partialFlight })
      } else {
         values?.request[indexRequest]?.flight.forEach((item, index) => {
            if (index !== 0) {
               remove(index)
            }
         })
      }
   }, [values?.request[indexRequest].typeFlight])

   return values?.request[indexRequest]?.flight?.map((itm, indexFlight) => (
      <div key={indexFlight}>
         {values?.request[indexRequest]?.flight?.length > 1 && <>
            <hr className="border-top border-gray-300 border-dashed h-1 w-full mb-4" />
            <div className="flex justify-end mb-4">
               <button type="button" onClick={() => remove(indexFlight)}><Close className="scale-[0.7]" /></button>
            </div>
         </>}
         <div className="grid grid-cols-1 gap-4 mb-1 md:grid-cols-2">
            <Input placeholder="Origen" name={`request[${indexRequest}].flight[${indexFlight}].airportsDeparture`} type="text" icon="origin" />
            <Input placeholder="Destino" name={`request[${indexRequest}].flight[${indexFlight}].airportsArrive`} type="text" icon="pointer" />
         </div>
         {(values?.request[indexRequest]?.typeFlight !== "Redondo") &&
            <div className="grid grid-cols-1 gap-4 mb-1 md:grid-cols-2">
               <DateInput name={`request[${indexRequest}].flight[${indexFlight}].dateDeparture`} label="Fecha" type={values?.request[indexRequest]?.typeFlight} />
               <Select label="Horario:" name={`request[${indexRequest}].flight[${indexFlight}].scheduleDeparture`} options={schedules} />
            </div>}
         {(values?.request[indexRequest]?.typeFlight === "Redondo") && <>
            <div className="grid grid-cols-1 gap-4 mb-1">
               <DateRange name={`request[${indexRequest}].flight[${indexFlight}].datesFlights`} nameDeparture={`request[${indexRequest}].flight[${indexFlight}].dateDeparture`} nameReturn={`request[${indexRequest}].flight[${indexFlight}].dateReturn`} label="Fechas de viaje" />
            </div>
            <div className="grid grid-cols-1 gap-4 mb-1 md:grid-cols-2">
               <Select label="Horario de ida:" name={`request[${indexRequest}].flight[${indexFlight}].scheduleDeparture`} options={schedules} />
               <Select label="Horario de vuelta:" name={`request[${indexRequest}].flight[${indexFlight}].scheduleReturn`} options={schedules} />
            </div>
         </>
         }
         <div className="w-fill sm:w-1/2 mb-8">
            <Select label="Clase:" name={`request[${indexRequest}].flight[${indexFlight}].class`} options={classes} type="h" />
         </div>
         <TextArea label="Comentarios" name={`request[${indexRequest}].flight[${indexFlight}].comments`} />
         {(indexFlight === values?.request[indexRequest]?.flight?.length - 1 && values?.request[indexRequest]?.typeFlight === 'Multidestino') &&
            <ActionButton className="flex w-fit gap-4 items-center mb-10" onClick={() => push({ ...partialFlight })}>Agregar vuelo <Plus className="text-lg" /></ActionButton>
         }
         <HiddenInput name={`request[${indexRequest}].flight[${indexFlight}].typeFlight`} value={values?.request[indexRequest]?.typeFlight} />
      </div>
   ))
}

export default FlightsFields
