import * as Yup from "yup";
import "yup-phone-lite";
import dayjs from 'dayjs'
Yup.addMethod(Yup.object, 'dayjs', function method(message) {
   return this.test('dayjs', message, function validate(value) {
      if (!value) {
         return true
      }
      return dayjs.isDayjs(value)
   })
})


export const formValidationSchema = Yup.object().shape({
   clientName: Yup.string().required("Este campo es requerido"),
   clientLastname: Yup.string().required("Este campo es requerido"),
   clientEmail: Yup.string()
      .email("Se requiere un correo válido")
      .required("Este campo es requerido"),
   clientPhone: Yup.string()
      .phone("MX", "Por favor, ingresa un número válido")
      .required("Este campo es requerido"),
   clientBirthday: Yup.string().required("Este campo es requerido"),
   clientBussiness: Yup.string(),
   clientEvent: Yup.string(),
   clientDocument: Yup.string(),
   docImage: Yup.string(),
   imageName: Yup.string(),
   request: Yup.array().of(
      Yup.object().shape({
         clientInsurance: Yup.boolean(),
         wantFlight: Yup.boolean(),
         wantHotel: Yup.boolean(),
         wantCar: Yup.boolean(),
         typeFlight: Yup.string().required("Este campo es requerido"),
         flight: Yup.array().when("wantFlight", {
            is: true,
            then: () =>
               Yup.array().of(
                  Yup.object().shape({
                     typeFlight: Yup.string(),
                     airportsDeparture: Yup.string().required("Este campo es requerido"),
                     airportsArrive: Yup.string().required("Este campo es requerido"),
                     datesFlights: Yup.array().when('typeFlight', {
                        is: 'Redondo',
                        then: () => Yup.array().of(Yup.object().dayjs('Fechas inválidas')).required(),
                     }),
                     dateDeparture: Yup.string().when("typeFlight", {
                        is: value => value !== 'Redondo',
                        then: () => Yup.string().required("Este campo es requerido"),
                        otherwise: () => Yup.string(),
                     }),
                     dateReturn: Yup.string(),
                     scheduleDeparture: Yup.string(),
                     scheduleReturn: Yup.string(),
                     class: Yup.string().required("Este campo es requerido"),
                     comments: Yup.string(),
                  }),
               ),
         }),
         hotel: Yup.array().when("wantHotel", {
            is: true,
            then: () =>
               Yup.array().of(
                  Yup.object().shape({
                     place: Yup.string().required("Este campo es requerido"),
                     datesHotel: Yup.array().of(Yup.object().dayjs('Fechas inválidas')).required(),
                     checkIn: Yup.string(),
                     checkOut: Yup.string(),
                     breakfast: Yup.boolean(),
                     ocupation: Yup.string(),
                     comments: Yup.string(),
                  })
               ),
         }),
         car: Yup.array().when("wantCar", {
            is: true,
            then: () =>
               Yup.array().of(
                  Yup.object().shape({
                     returnInOther: Yup.boolean(),
                     place: Yup.string().required("Este campo es requerido"),
                     datesCar: Yup.array().of(Yup.object().dayjs('Fechas inválidas')).required(),
                     pickDate: Yup.string(),
                     pickHour: Yup.string(),
                     returnHour: Yup.string(),
                     returnDate: Yup.string(),
                     returnPlace: Yup.string().when("returnInOther", {
                        is: true,
                        then: () => Yup.string().required("Este campo es requerido"),
                        otherwise: () => Yup.string(),
                     }),
                     comments: Yup.string(),
                  })
               ),
         }),
      })
   ),
});
