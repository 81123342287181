import * as React from "react"
const Calendar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#000"
        d="M7.292 11.458h2.083v2.084H7.292v-2.084ZM21.875 6.25v14.583a2.09 2.09 0 0 1-2.083 2.084H5.208a2.083 2.083 0 0 1-2.083-2.084l.01-14.583c0-1.146.917-2.083 2.073-2.083H6.25V2.083h2.083v2.084h8.334V2.083h2.083v2.084h1.042a2.09 2.09 0 0 1 2.083 2.083ZM5.208 8.333h14.584V6.25H5.208v2.083Zm14.584 12.5V10.417H5.208v10.416h14.584Zm-4.167-7.291h2.083v-2.084h-2.083v2.084Zm-4.167 0h2.084v-2.084h-2.084v2.084Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h25v25H0z" />
      </clipPath>
    </defs>
  </svg>
)
export {Calendar}