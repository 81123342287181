import { useState } from 'react';
import logo from './assets/logo.jpg'
import { Formik } from 'formik';
import { initialValues } from './helpers/initalValues';
import { formValidationSchema } from './helpers/validationSchema';
import CustomForm from './sections/CustomForm';
import './App.css';
import Modal from './components/Modal';
import { Check, Close } from './icons';

function App() {
   const [loading, setLoading] = useState(false)
   const [modal, setModal] = useState(undefined)
   const [formActions, setFormActions] = useState(undefined)

   const handleCloseModal = () => {
      if (modal === "success") {
         window.location.reload()
      }

      setModal(undefined);
   }

   return (
      <main className="bg-white py-8 px-4">
         <section className="container w-full mx-auto shadow-[0_9px_20px_20px_rgba(217,217,217,0.3)] rounded-[15px] p-4 md:p-8 max-w-[700px]">
            <Formik
               enableReinitialize
               initialValues={initialValues}
               validationSchema={formValidationSchema}
               onSubmit={(values, actions, errors) => {
                  setLoading(true)
                  setTimeout(() => {
                     fetch("api/form", {
                        method: "POST",
                        body: JSON.stringify(values),
                        headers: { "Content-Type": "application/json" }
                     }).then(response => response.json()).then(res => {
                        setLoading(false)
                        setModal(res.status);
                        setFormActions(actions)
                     }).catch(error => {
                        setModal(error)
                        setLoading(false)
                     })
                     actions.setSubmitting(false);
                  }, 1000)
               }}
            >
               {(props) => {
                  return (
                     <>
                        <header>
                           <img src={logo} className="object-contain mx-auto mb-8" alt="logo" width={350} />
                        </header>
                        <CustomForm {...props} />
                     </>
                  )
               }}
            </Formik>
         </section>
         {modal &&
            <Modal closeAction={() => handleCloseModal()}>
               {modal === 'success' ? <>
                  <Check className="mx-auto mb-6" />
                  <h2 className="text-2xl md:text-3xl italic text-center mb-3 mb">¡Gracias por completar el formulario!</h2>
                  <h2 className="text-2xl md:text-3xl italic text-center">Recibimos tu solicitud</h2>
               </> : <>
                  <Close className="scale-[3] text-red-500 mx-auto mb-12" />
                  <h2 className="text-2xl md:text-3xl italic text-center mb-3 mb">¡Hubo un error!</h2>
                  <h2 className="text-2xl md:text-3xl italic text-center">Intenta de nuevo más tarde</h2>
               </>
               }
            </Modal>
         }
         {loading &&
            <Modal>
               <h2 className="text-2xl md:text-3xl italic text-center">Procesando solicitud...</h2>
            </Modal>
         }
      </main>
   );
}

export default App;
