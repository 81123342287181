import * as React from "react"
const User = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M9 2.392a2.275 2.275 0 1 1 0 4.55 2.275 2.275 0 0 1 0-4.55Zm0 9.75c3.218 0 6.608 1.581 6.608 2.275v1.191H2.393v-1.191c0-.694 3.39-2.275 6.608-2.275ZM9 .333a4.332 4.332 0 0 0-4.333 4.334A4.332 4.332 0 0 0 9 9a4.332 4.332 0 0 0 4.334-4.333A4.332 4.332 0 0 0 9 .333Zm0 9.75c-2.892 0-8.667 1.452-8.667 4.334v3.25h17.334v-3.25c0-2.882-5.774-4.334-8.667-4.334Z"
    />
  </svg>
)
export { User }
