import React from "react";
import Select from '../components/Select';
import DateInput from '../components/DateInput';
import Checkbox from "../components/Checkbox";
import { Plus } from "../icons/Plus";
import ActionButton from "../components/ActionButton";
import { FieldArray } from "formik";
import { Close } from "../icons/Close";
import { partialCar } from "../helpers/initalValues";
import Time from "../components/Time";
import Input from "../components/Input";
import TextArea from "../components/TextArea";
import DateRange from "../components/DateRange";

const Car = ({ indexRequest }) => {

   return (
      <FieldArray name={`request[${indexRequest}].car`}>
         {({ push, remove, form: { values } }) => {

            return values.request[indexRequest]?.car?.map((itm, indexCar) => (
               <div key={indexCar}>
                  {values.request[indexRequest]?.car?.length > 1 && <>
                     <hr className="border-top border-gray-300 border-dashed h-1 w-full mb-4" />
                     <div className="flex justify-end">
                        <button type="button" onClick={() => remove(indexCar)}><Close className="scale-[0.7]" /></button>
                     </div>
                  </>}
                  <div className="md:flex gap-4 mb-6">
                     <p className="text-md font-semibold">AUTO:</p>
                     <Checkbox name={`request[${indexRequest}].car[${indexCar}].returnInOther`} label="Devolver el vehículo en otro destino" labelStyle="font-bold text-[20px]" />
                  </div>
                  <div className="mb-1">
                     <Input placeholder="Lugar de retiro ciudad o aeropuerto" name={`request[${indexRequest}].car[${indexCar}].place`} type="text" icon="origin" />
                     {values.request[indexRequest].car[indexCar].returnInOther &&
                        <Input placeholder="Lugar de devolución ciudad o aeropuerto" name={`request[${indexRequest}].car[${indexCar}].returnPlace`} type="text" icon="origin" />
                     }
                  </div>
                  <div className="grid gap-4 mb-1 grid-cols-1">
                     <DateRange name={`request[${indexRequest}].car[${indexCar}].datesCar`} nameDeparture={`request[${indexRequest}].car[${indexCar}].pickDate`} nameReturn={`request[${indexRequest}].car[${indexCar}].returnDate`} label="Fechas de reservación" />
                  </div>
                  <div className="grid md:grid-cols-2 gap-x-4 mb-1 grid-cols-1">
                     <Time name={`request[${indexRequest}].car[${indexCar}].pickHour`} label="Hora de Retiro" />
                     <Time name={`request[${indexRequest}].car[${indexCar}].returnHour`} label="Hora de Devolución" />
                  </div>
                  <TextArea label="Comentarios" name={`request[${indexRequest}].car[${indexCar}].comments`} />
                  {(indexCar === values.request[indexRequest]?.car?.length - 1) &&
                     <ActionButton className="flex w-fit gap-4 items-center mb-6" onClick={() => push({ ...partialCar })}>Agregar auto <Plus className="text-lg" /></ActionButton>
                  }
               </div>
            ))
         }}
      </FieldArray>
   );
}

export default Car;