import React from "react";
import { useField } from "formik";
import 'dayjs/locale/es-mx';
import Icon from "./Icon";
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StyledEngineProvider } from '@mui/material/styles';
import { esES } from '@mui/x-date-pickers/locales';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs'

const DateRange = (props) => {
   const { label, name, nameDeparture, nameReturn } = props
   const [field, meta, helpers] = useField(name);
   const [fieldDeparture, metaDeparture, helpersDeparture] = useField(nameDeparture);
   const [fieldReturn, metaReturn, helpersReturn] = useField(nameReturn);

   return (
      <div className="relative mb-4">
         {label &&
            <label htmlFor='dateRange' className={`mb-1 text-base font-semibold leading-5 ${(meta?.touched && meta?.error) ? 'text-red-500' : 'text-[#0A0A0A]'}`}>{label}</label>
         }
         <div className="left-[16px] top-[36px] absolute">
            <Icon type="date" />
         </div>
         <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es-MX'} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
            <StyledEngineProvider injectFirst>
               <DateRangePicker
                  name={field.name}
                  value={field.value}
                  calendars={1}
                  format="DD-MM-YYYY"
                  disablePast={true}
                  required
                  slots={{ field: SingleInputDateRangeField }}
                  onChange={(value) => {
                     const dateDeparture = dayjs(value[0]).format('DD-MM-YYYY')
                     const dateReturn = value[1] ? dayjs(value[1]).format('DD-MM-YYYY') : undefined;
                     helpers.setValue(value)
                     helpersDeparture.setValue(dateDeparture)
                     helpersReturn.setValue(dateReturn)
                  }}
               />
            </StyledEngineProvider>
         </LocalizationProvider>
         {meta?.touched && meta?.error &&
            <span className="text-red-500 block h-6">Una o más fechas son inválidas</span>
         }

      </div >
   );
}

export default DateRange;