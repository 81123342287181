import { useField } from "formik";
import React from "react";

const Select = (props) => {
  const { label, name, options, type = 'v' } = props
  const [field, meta] = useField(name);
  return (<>
    <div className={type === 'h' ? 'flex w-full items-center' : ''}>
      <label htmlFor={name} className={`${meta?.touched && meta?.error ? 'text-red-500' : 'text-[#0A0A0A]'} mb-1 text-base font-semibold leading-5 ${type === 'h' ? 'w-fit me-4' : 'w-full'}`}>{label}</label>
      <select
        {...field}
        {...props}
        className={`border border-solid border-black h-[46px] rounded-[5px] placeholder:text-[#ACACAC] py-2 px-4 w-full`}
      >
        {options && options.map((item, index) => (
          <option value={item.value} key={index}>{item.name}</option>
        ))}
      </select>
      <span className="text-red-500 block h-6">{meta?.error}</span>
    </div>
  </>
  );
}

export default Select;