import React from "react";
import { useField } from "formik";
import { toBase64 } from "../helpers/toBase64";
import { Close } from "../icons";
const File = (props) => {
  const { label, name, nameImage } = props
  const [field, meta, helper] = useField(name);
  const [fieldImage, metaImage, helperImage] = useField(nameImage);

  const clearField = () => {
    helperImage.setValue('')
    helper.setValue('');
  }

  return (
    <div className={`border ${!(meta?.touched && meta?.error) ? 'border-[#D9D9D9]' : 'border-red-500'} border-dashed p-10 w-full justify-center items-center flex flex-col`}>
      <label htmlFor={name} className={`mb-1 text-base font-semibold leading-5 ${meta?.touched && meta?.error ? 'border-red-500' : ''} bg-primary text-white hover:bg-accent disabled:bg-gray-300 py-3 px-4 rounded-[5px]`}>{label}</label>
      <input
        {...props}
        id={name}
        onChange={async (e) => {
          helperImage.setValue(e.currentTarget.files[0].name)
          const imageToString = await toBase64(e.currentTarget.files[0]);
          helper.setValue(imageToString);
        }}
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        className={`opacity-0 absolute top-0 pointer-events-none`}
      />
      {fieldImage.value && <div className="flex">
        <p>{fieldImage.value}</p>
        <button className="" onClick={() => clearField()}><Close className="scale-[0.5]" /></button>
      </div>
      }
      {meta?.touched && meta?.error &&
        <span className="text-red-500">{meta?.error}</span>
      }
    </div>
  );
}

export default File;