import { useField } from "formik";
import React from "react";

const Checkbox = (props) => {
  const { label, labelStyle, name, } = props

  const [field, meta] = useField(name);

  return (
    <div className="flex gap-4 w-fit items-center">
      <label className="flex relative items-center rounded-full cursor-pointer" htmlFor={name}>
        <input
          type="checkbox"
          id={name}
          {...field}
          {...props}
          checked={field.value}
          className="before:content[''] peer relative h-6 w-6 cursor-pointer appearance-none rounded-sm border border-black transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:before:bg-gray-900 hover:before:opacity-10 after:content[''] after:absolute after:top-[50%] after:left-[50%] after:translate-x-[-50%] after:translate-y-[-50%] after:w-[calc(100%-7px)] after:h-[calc(100%-7px)] checked:after:bg-primary"
        />
      </label>
      <label className={`mt-px font-light text-gray-700 cursor-pointer select-none ${labelStyle}`} htmlFor={name}>
        {label}
      </label>
      <span className="text-red-500 block h-6">{meta?.error}</span>
    </div>
  );
}

export default Checkbox;