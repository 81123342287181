import React from "react";
import Flights from "./Flights";
import Hotel from "./Hotel";
import Car from "./Car";
import Checkbox from "../components/Checkbox";
import { Close } from "../icons";
import { useField } from "formik";

const Request = (props) => {
  const [field] = useField('request');
  const { indexRequest, onDelete, showCloseButton } = props

  return (
    <>
      <div className="flex justify-between items-center mb-3">
        <h4 className="text-sm font-semibold uppercase ps-1">Solicitud {indexRequest + 1}</h4>
        {showCloseButton &&
          <button type="button" onClick={() => onDelete()}><Close className="scale-[0.7]" /></button>
        }
      </div>

      <article className="border border-black rounded-[15px] p-4 mb-5">
        <h4 className="text-[15px] italic mb-3">Selecciona los servicios:</h4>
        <div className="grid grid-cols-1 gap-4 mb-10 md:grid-cols-4 w-full">
          <Checkbox label="Vuelo" name={`request[${indexRequest}].wantFlight`} />
          <Checkbox label="Hotel" name={`request[${indexRequest}].wantHotel`} />
          <Checkbox label="Auto" name={`request[${indexRequest}].wantCar`} />
          <Checkbox label="Seguro de viaje" name={`request[${indexRequest}].clientInsurance`} />
        </div>
        {field?.value?.[indexRequest]?.wantFlight && <Flights indexRequest={indexRequest} />}
        {field?.value?.[indexRequest]?.wantHotel && <Hotel indexRequest={indexRequest} />}
        {field?.value?.[indexRequest]?.wantCar && <Car indexRequest={indexRequest} />}
      </article>
    </>
  );
}

export default Request;