import React from "react";
import { partialRequest } from "../helpers/initalValues";
import { Form } from "formik";
import Request from './Request';
import Creator from './Creator';
import ActionButton from '../components/ActionButton';
import { Plus, Arrow } from '../icons'

const CustomForm = (props) => {
   const handleOnAddRequest = () => {
      props.setFieldValue('request', [...props?.values?.request, { ...partialRequest }])
   }
   const handleDeleteRequest = (index) => {
      const updatedRequests = props?.values?.request?.filter((_, i) => i !== index);
      props.setFieldValue('request', updatedRequests)
   }
   return props?.values && <Form>
      <Creator />
      {
         props?.values?.request?.map((itm, index) => (
            <Request
               key={index}
               indexRequest={index}
               showCloseButton={props.values.request.length > 1}
               onDelete={() => handleDeleteRequest(index)}
            />
         ))
      }
      <footer className="my-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
         <ActionButton
            variant="secondary"
            className="flex w-full gap-4 items-center justify-between font-bold"
            onClick={handleOnAddRequest}
         >
            Agregar solicitud de viaje <Plus />
         </ActionButton>
         <ActionButton
            className="flex w-full gap-4 items-center uppercase font-bold justify-between"
            // disabled={!props.isValid}
            type='submit'
         >
            Enviar solicitud <Arrow />
         </ActionButton>
      </footer>
   </Form>
}

export default CustomForm;