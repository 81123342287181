import React from "react";
const Modal = ({ children, closeAction }) => {
  return (
    <section className="relative z-10 w-full" aria-labelledby="modal-title" role="dialog" aria-modal="true" onClick={() => closeAction && closeAction()}>
      <article className="fixed inset-0 w-screen overflow-y-auto bg-black bg-opacity-60 transition-opacity flex min-h-full items-center justify-center p-4">
        <div className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all px-5 py-4">
          <div className="border border-solid border-black rounded-3xl px-4 py-16">
            {children}
          </div>
        </div>
      </article>
    </section>);
}

export default Modal;