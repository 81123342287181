import { Origin, Calendar, Pointer, Toggle, User, Plus, Arrow, Close } from '../icons'

const Icon = ({type}) => {

    let component;
    
    switch (type){
      case 'date':
        component = <Calendar/>;
        break;
      case 'origin':
        component = <Origin/>
        break;
      case 'pointer':
        component = <Pointer/>
        break;
      case 'toggle':
        component = <Toggle/>
        break;
      case 'user':
        component = <User/>
        break;
      case 'plus':
        component = <Plus/>
        break;
      case 'arrow':
        component = <Arrow/>
        break;
      case 'close':
        component = <Close/>
        break;
      default:
        component = undefined;
        break;
  }
  return component;
}
 
export default Icon;