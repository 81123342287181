import React from "react";
import { useField } from "formik";
import { getMaxDate, getMinDate, getToday } from "../helpers/formattedDate";
import Icon from "./Icon";

const Input = (props) => {
  const { label, name, type } = props
  const [field, meta] = useField(name);

  let minDate; // cuantos años hacia el pasado
  let maxDate; // cuandos años hacia el futuro
  switch (type) {
    case 'birthday':
      minDate = getMinDate(100);
      maxDate = getMaxDate(18);
      break;
    case 'rangeDays':
      minDate = getToday();
      maxDate = undefined;
      break;
    default:
      minDate = undefined;
      maxDate = undefined;
  }

  return (
    <div className="relative">
      {label &&
        <label htmlFor={name} className={`mb-1 text-base font-semibold leading-5 ${meta?.touched && meta?.error ? 'text-red-500' : 'text-[#0A0A0A]'}`}>{label}</label>
      }
      <div className="left-[16px] top-[36px] absolute">
        <Icon type="date"/>
      </div>
      <input
        {...props}
        {...field}
        min={minDate}
        max={maxDate}
        type="date"
        className={`border border-solid ${meta?.touched && meta?.error ? 'border-red-500' : 'border-black'} h-[52px] rounded-[5px] w-full placeholder:text-[#ACACAC] py-2 px-4 invalid:border-red-500`}
      />
      <span className="text-red-500 block h-6">{meta?.touched && meta?.error}</span>
    </div>
  );
}

export default Input;