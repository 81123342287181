import React from 'react';
import { FieldArray } from "formik";
import Select from '../components/Select'
import FlightsFields from '../components/FlightsFields';

const Flights = ({ indexRequest }) => {
   const type = [{
      value: "Redondo",
      name: "Redondo"
   }, {
      value: "Sencillo",
      name: "Sencillo"
   }, {
      value: "Multidestino",
      name: "Multidestino"
   },
   ]

   return (<>
      <div className="w-fill mb-4 sm:w-1/2">
         <Select label="Vuelo:" name={`request[${indexRequest}].typeFlight`} options={type} type="h" />
      </div>
      <FieldArray name={`request[${indexRequest}].flight`}>
         {(props) => <FlightsFields indexRequest={indexRequest} {...props} />
         }

      </FieldArray>
   </>
   );
}

export default Flights;