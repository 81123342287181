import React from "react";
import Input from "../components/Input";
import DateInput from "../components/DateInput";
import File from "../components/File";
import Checkbox from "../components/Checkbox";

const Creator = () => {

  return (
    <article>
      <div className="grid grid-cols-1 gap-x-4 gap-y-1 md:grid-cols-2 mb-4">
        <Input label="Nombre" placeholder="Nombre" name="clientName" type="text" />
        <Input label="Apellidos" placeholder="Apellidos" name="clientLastname" type="text" />
        <Input label="Correo electrónico" placeholder="Correo electrónico" name="clientEmail" type="email" />
        <Input label="Número celular" placeholder="Ingresa tu number" name="clientPhone" type="tel" />
        <DateInput label="Fecha de nacimiento" name="clientBirthday" type="birthday" />
        <Input label="Empresa" placeholder="Empresa" name="clientBussiness" type="text" />
        <Input label="Evento" placeholder="Evento" name="clientEvent" type="text" />
        <Input label="No. de documento" placeholder="No. de documento" name="clientDocument" type="text" />
      </div>
      <div className="mb-4">
        <File label="Adjunta tu Pasaporte o INE" name="docImage" nameImage="imageName" />
      </div>
      <hr className="h-0 border border-[#D9D9D9] block my-12" />
    </article>
  );
}

export default Creator;