import * as React from "react"
const Origin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    {...props}
  >
    <circle cx={10} cy={10} r={10} fill="#727272" />
    <circle cx={10} cy={10} r={4} fill="#fff" />
  </svg>
)
export { Origin }