import React from "react";
import { useField } from "formik";

const TextArea = (props) => {
    const { label, name } = props
    const [field, meta] = useField(name);

    return (
        <div className="relative">
            {label &&
                <label htmlFor={name} className={`mb-1 text-base font-semibold leading-5 ${meta?.touched && meta?.error ? 'text-red-500' : 'text-[#0A0A0A]'}`}>{label}</label>
            }
            <textarea
                {...field}
                {...props}
                id={name}
                rows={5}
                className={`border border-solid ${meta?.touched && meta?.error ? 'border-red-500' : 'border-black'} h-[52px] rounded-[5px] w-full placeholder:text-[#ACACAC] py-2 pe-4`}
            />
            <span className="text-red-500 block h-6">{meta?.error}</span>
        </div>
    );
}

export default TextArea;